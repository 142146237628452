<!--
    内容：我的考勤
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <filter-section ref="filterSection" @search="handleSearh" optFlag="employee"></filter-section>       
        <!-- 考勤列表 -->
        <div class="cell-table">
            <div class="d-flex a-center bg-white text-muted2">
                <div class="cell-td">日期</div>
                <div class="cell-td">上班</div>
                <div class="cell-td">下班</div>
                <div class="cell-td">备注</div>
            </div>
            <div class="d-flex a-center" 
                    :class="{'odd':index%2===1}" 
                    style="color: #333;font-weight:bold;" 
                    v-for="(item,index) in tableData" 
                    :key="index">
                <div class="cell-td">
                  <span>{{item.date}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.onWork}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.offWork}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.remarks}}</span>
                </div>
            </div>
            <van-empty v-if="tableData.length===0"/>
        </div>
    </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'myAttendance' ,
    components:{
        filterSection,
    },
    data(){
        return{
            form : {
                //开始时间
                startDate: "",
                //结束时间
                endDate: "",
                //门店编码
                storeId : "",
            },
            tableData : [],
        }
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryList();
        },
        /**
         * 获取员工考勤列表
         */
        queryList() {
            ajax.post('getStaffWorks',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then(res=>{
                if (res.responeCode==='0' && res.data) {
                    this.tableData = res.data || [];
                } else {
                    this.tableData = [];
                }
            })
        },
    },
}
</script>
<style scoped lang='scss'>
.page{  
  .top-cell{
    margin-top: 40px;
    .radius{
      border-radius: 12px;
      background-color: #fff;
      padding: 40px 20px;
      b{
        font-weight: bold;
      }
      i{
        font-style: normal;
      }
    }
    .total{
      text-align: center;
      font-size: 28px;
      span{
        display: block;
      }      
    }
    ul.performance-li{
      padding-top:50px;
      padding-bottom: 15px;
      display: flex;
      li{
        width: 50%;
        text-align: center;
        border-right: 2px solid #f0ebeb;
        span{
          display: block;
        }
        &:last-child{
          border: none;
        }
      }
    }
  }
  .cell-table{
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    .cell-td{
      padding: 20px 10px;
      width: 25%;
      text-align: center;
    }  
    .odd{
      background-color: #f7f8fc;
    }
  } 
}
</style>