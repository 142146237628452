<!--
    内容：我的提成
    作者：ada-xu
-->
<template>
    <div class='page scroll'>

        <filter-section ref="filterSection" @search="handleSearh" optFlag="employee"></filter-section>

        <!-- 总数据 -->
        <div class="radius mt-24 top-cell">
            <div class="total">
                <span class="font-44"><b class="text-danger">{{summary.totalRewardAmount | moneyFilter}}</b></span>
                <span class="text-muted">总提成</span>
            </div>
            <ul class="performance-li">
                <li>
                    <span class="font-28"><b class="text-danger">{{summary.cashRewardAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">现金提成</span>
                </li>
                <li>
                    <span class="font-28"><b class="text-danger">{{summary.cardRewardAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">卡金提成</span>
                </li>
                <li>
                    <span class="font-28"><b class="text-danger">{{summary.speRewardAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">指定提成</span>
                </li>
                <li>
                    <span class="font-28"><b class="text-danger">{{summary.unSpeRewardAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">非指定提成</span>
                </li>
            </ul>
        </div>

        <div class="cell-table">
            <div class="d-flex a-center bg-white text-muted">
                <div class="cell-td">服务项目</div>
                <div class="cell-td">提成</div>
                <div class="cell-td">数量</div>
                <div class="cell-td">指定</div>
                <div class="cell-td">类型</div>
            </div>
            <div class="d-flex a-center tr-table" style="color: #333;font-weight:bold;" v-for="(item,index) in tableList" :key="index">
                <div class="cell-td">
                    <span>{{item.itemName}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.rewardAmount}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.resultsCnt}}</span>
                </div>
                <div class="cell-td">
                    <span :class="{'text-danger':item.specialType==='1','text-success':item.specialType==='0'}">{{item.specialType==='1'?'指定':'非指定'}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.resultsType}}</span>
                </div>
            </div>
            <van-empty v-if="tableList.length===0"/>
        </div>

    </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'myCommission' ,
    components: {
        filterSection,
    },
    data() {
        return {
            form : {
                //开始时间
                startDate: "",
                //结束时间
                endDate: "",
                //门店编码
                storeId : "",
            },
            tableList : [],
            //总提成
            summary : {},
        }
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryStaffSummaryResults();
        },
        /**
         * 获取员工业绩和提成
         */
        queryStaffSummaryResults() {
            ajax.post('staffSummaryResults',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then( res=> {
                if ( res.responeCode === '0' && res.data ) {
                    this.summary = res.data.summary || {};
                    this.tableList = res.data.projects || [];
                } else{
                    this.summary = {};
                    this.tableList = [];
                }
            })
        },
    },
}
</script>
<style scoped lang='scss'>
.page {
    .radius{
        border-radius: 12px;
        background-color: #fff;
        padding: 40px 20px;
        b {
            font-weight: bold;
        }
    }
    .top-cell {
        .total {
            text-align: center;
            font-size: 28px;
            span{
                display: block;
            }
        }
        .performance-li{
            padding-left: 40px;
            padding-right: 40px;
            display: flex;
            flex-wrap: wrap;
            li{
                width: 50%;
                text-align: center;
                padding-top: 15px;
                padding-bottom: 25px;
                border-top: 1px solid #F5F5F5;
                box-sizing: border-box;
                span{
                    display: block;
                }
                &:nth-of-type(2n-1){
                    border-right: 1px solid #F5F5F5;
                }
                &:nth-of-type(1),&:nth-of-type(2){
                    border-top: none;
                }
            }
        }
    }
    .cell-table{
        margin-top: 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 12px;
        .cell-td{
            padding: 20px 10px;
            width: 25%;
            text-align: center;
            overflow: hidden;
        } 
        .tr-table{
            border-bottom: 1px solid #F5F5F5;
            color: #333;
            font-weight:bold;
            &:last-child{
            border-bottom: none;
            }
        }
    }    
    .mt-24{
        margin-top: 24px;
    }
}

</style>