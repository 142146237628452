<!-- 
  服务记录
--> 
<template>
    <div class='member_pck scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="服务记录"></header-tag> -->
        <div id="list">
            <div class="records" v-if="tbData.length>0">
                <div class="item" v-for="(item,index) in tbData" :key="index">
                    <p class="d-flex a-end j-sb" style="margin-bottom:14px;">
                        <span class="name">{{item.orderTypeName}}</span>
                        <span class="time">
                            <img src="@/assets/img/fwjl_icon_shij@2x.png" alt="" class="time-icon">
                            <span>{{item.createDate}}</span>
                        </span>
                    </p>
                    <p class="content">
                        <span>服务员工:</span>
                        <span>{{item.staffNames}}</span>
                    </p>
                    <p class="content">
                        <span>服务项目:</span>
                        <span>{{item.projectName}}</span>
                    </p>
                    <p class="price">{{item.orderPrice | moneyFilter}}</p>
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>        
    </div>
</template>

<script>
import headerTag from '@/components/headerTag';
import ajax from '@/api/index';
export default {
    name : 'servieRecord',
    components: {
        headerTag
    },
    data() {
      return {
          tbData : [],
      };
    },
    created(){
        this.queryOrdersList();
    },
    methods : {
        /**
         * 查询门店会员的套餐列表
         */
        queryOrdersList(){
            ajax.post('getCustomerOrders').then(res=>{
                if(res.responeCode==='0'){
                  this.tbData = res.data || [];
                }else{
                  this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.records{
    .item{
        background-color: #fff;
        border-radius: 12px;
        margin-bottom: 24px;
        padding:24px;
    }
    .name {
        color: #333;
        font-weight: bold;
        font-size: 34px;
    }
    .time{
        color: #666;
        .time-icon{
            vertical-align: middle;
            width :22px;
            height: 22px;
            margin-right: 10px;
        }
    }
    .price{
        color: #FF0202;
        font-weight: 34px;
        font-weight: bold;
        text-align: right;
        padding-top: 15px;
    }
    .content{
        font-size: 28px;
        margin-bottom: 10px;
        span:nth-of-type(1){
            color: #999;
        }
        span:nth-of-type(2){
            margin-left: 20px;
            color: #333;
            font-weight: bold;
        }
    }
}
</style>