import { render, staticRenderFns } from "./staffRegister.vue?vue&type=template&id=0b0cd4d2&scoped=true&"
import script from "./staffRegister.vue?vue&type=script&lang=js&"
export * from "./staffRegister.vue?vue&type=script&lang=js&"
import style0 from "./staffRegister.vue?vue&type=style&index=0&id=0b0cd4d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0cd4d2",
  null
  
)

export default component.exports