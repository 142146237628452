import { render, staticRenderFns } from "./myCommission.vue?vue&type=template&id=61a33e0d&scoped=true&"
import script from "./myCommission.vue?vue&type=script&lang=js&"
export * from "./myCommission.vue?vue&type=script&lang=js&"
import style0 from "./myCommission.vue?vue&type=style&index=0&id=61a33e0d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61a33e0d",
  null
  
)

export default component.exports