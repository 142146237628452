<!--
    内容：我的总览
    作者：ada-xu
-->
<template>
    <div class='page scroll'>

        <filter-section ref="filterSection" @search="handleSearh" optFlag="employee"></filter-section>

        <!-- 业绩排行榜 --> 
        <div class="top-cell" v-if="rankList.length">
            <div class="radius">
                <div class="ranking">
                    <!-- 第二名 -->
                    <div class="rankitem rankitem_two">
                        <div v-if="rankList.length>1">
                            <div class="avator-box">
                                <img src="@/assets/img/avator.png" class="user_avator">
                                <img src="@/assets/img/wdzl_icon_yajun@2x.png" class="wdzl_icon"/>
                            </div>
                            <p class="name">{{rankList[1].staffName}}</p>
                            <p class="text-danger font-20">{{rankList[1].resultsAmount | moneyFilter}}</p>
                        </div>
                    </div>
                    <!-- 第一名 -->
                    <div class="rankitem rankitem_one">
                        <div class="avator-box">
                            <img src="@/assets/img/avator.png" class="user_avator">
                            <img src="@/assets/img/wdzl_icon_guanj@2x.png" class="wdzl_icon"/>
                        </div>
                        <p class="name">{{rankList[0].staffName}}</p>
                        <p class="text-danger font-20">{{rankList[0].resultsAmount | moneyFilter}}</p>
                    </div>
                    <!-- 第三名 -->
                    <div class="rankitem  rankitem_three">
                        <div v-if="rankList.length>2">
                            <div class="avator-box">
                                <img src="@/assets/img/avator.png" class="user_avator">
                                <img src="@/assets/img/wdzl_icon_jijun@2x.png" class="wdzl_icon"/>
                            </div>
                            <p class="name">{{rankList[2].staffName}}</p>
                            <p class="text-danger font-20">{{rankList[2].resultsAmount | moneyFilter}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 总数据 -->
        <div class="radius mt-24">
            <ul class="performance-li">
                <li>
                    <span class="font-28"><b class="text-danger">{{myResults.resultsAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">总业绩</span>
                </li>
                <li>
                    <span class="font-28"><b class="text-danger">{{myResults.rewardAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">总提成</span>
                </li>
            </ul>
        </div>

        <!-- 业绩列表 -->
        <div class="cell-table">
            <div class="d-flex a-center bg-white text-muted">
                <div class="cell-td">业绩类型</div>
                <div class="cell-td">数量</div>
                <div class="cell-td">业绩</div>
                <div class="cell-td">提成</div>
            </div>
            <div class="d-flex a-center tr-table" style="color: #333;font-weight:bold;" v-for="(item,index) in tableList" :key="index">
                <div class="cell-td">
                    <span>{{item.name}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.num}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.resultsAmount}}</span>
                </div>
                <div class="cell-td">
                    <span>{{item.rewardAmount}}</span>
                </div>
            </div>
            <van-empty v-if="tableList.length===0"/>
        </div>

    </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'myOverview' ,
    components: {
        filterSection,
    },
    data() {
        return{
            form : {
                //开始时间
                startDate: "",
                //结束时间
                endDate: "",
            },     
            //是否显示弹窗
            showPopup : false,
            //排名列表
            rankList : [],
            //项目业绩
            tableList : [],
            //我的总业绩
            myResults : {},
        }
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryStaffSummary();
        },
        /**
         * 获取员工业绩总览
         */
        queryStaffSummary() {
            ajax.post('staffSummary',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then(res=>{
                if(res.responeCode==='0' && res.data){
                    
                    //排名数据
                    let rankList = res.data.sortList || [];
                    this.rankList = [];
                    rankList.map((item,index)=>{
                        if (index<3) {
                            this.rankList.push(item);
                        }
                    })

                    //业绩按类型汇总
                    let myResultsList = res.data.myResultsList || {};
                    this.tableList = [ 
                        {
                            name : '充值',
                            resultsAmount  : myResultsList.addResultsAmount || '0',
                            rewardAmount  : myResultsList.addRewardAmount || '0',
                            num : myResultsList.addCnt || '0',
                        },
                        {
                            name : '开卡',
                            resultsAmount  : myResultsList.cardResultsAmount || '0',
                            rewardAmount  : myResultsList.cardRewardAmount || '0',
                            num : myResultsList.cardCnt || '0',
                        },
                        {
                            name : '项目',
                            resultsAmount  : myResultsList.projectResultsAmount || '0',
                            rewardAmount  : myResultsList.projectRewardAmount || '0',
                            num : myResultsList.projectCnt || '0',
                        },
                        {
                            name : '卖品',
                            resultsAmount  : myResultsList.goodsResultsAmount || '0',
                            rewardAmount  : myResultsList.goodsRewardAmount || '0',
                            num : myResultsList.goodsCnt || '0',
                        },
                        {
                            name : '套餐',
                            resultsAmount  : myResultsList.packageResultsAmount || '0',
                            rewardAmount  : myResultsList.packageRewardAmount || '0',
                            num : myResultsList.packageCnt || '0',
                        },
                    ]
                    
                    this.myResults = res.data.myResults || {};
                } else { 
                    this.rankList = [];
                    this.myResults = {};
                    this.tableList = [];
                    this.$toast(res.responeMsg || '查询失败')
                }
            })
        },
    },
}
</script>
<style scoped lang='scss'>
.page{
    .radius{
        border-radius: 12px;
        background-color: #fff;
        padding: 40px 20px;
        b{
        font-weight: bold;
        }
    }
  .top-cell{
    margin-top: 40px;
    .ranking{
      text-align: center;
      font-size: 28px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .rankitem{
          width: 33.33%;
          img{
            width: 60%;
          }
          .avator-box {
             position: relative;
             margin: 0 auto 20px; 
             border-radius: 50%;
          }
          .user_avator{
             display: block;
             width: 100%;
             height: 100%;
             border-radius: 50%;
          }
          .wdzl_icon{
            position: absolute;
            bottom: 0;
            right: 0;
          }
          p{
            font-weight: bold;
          }
          .name {
              font-size: 28px;
              color: #333;
              margin-bottom: 6px;
          }
      }
      .rankitem_one {
          .avator-box{
            width: 150px;
            height: 150px;
            border: 4px solid #FFD821;
          }
          .wdzl_icon{
            width: 48px;
            height: 40px;
          }
      }
      .rankitem_two {
          .avator-box{
            width: 120px;
            height: 120px;
            border: 4px solid #D5E2F0;
          }
          .wdzl_icon{
            width: 38px;
            height: 32px;
          }
      }
      .rankitem_three {
          .avator-box{
            width: 120px;
            height: 120px;
            border: 4px solid #EACE97;
          }
          .wdzl_icon{
            width: 38px;
            height: 32px;
          }
      }
      span{
        display: block;
      }
    }
  }
  .cell-table{
      margin-top: 20px;
      padding: 20px;
      background-color: #fff;
      border-radius: 12px;
      .cell-td{
          padding: 20px 10px;
          width: 25%;
          text-align: center;
      } 
      .tr-table{
          border-bottom: 1px solid #F5F5F5;
          color: #333;
          font-weight:bold;
          &:last-child{
            border-bottom: none;
          }
      }
  } 
  .performance-li{
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-wrap: wrap;
        li{
        width: 50%;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 25px;
        border-top: 1px solid #F5F5F5;
        box-sizing: border-box;
        span{
            display: block;
        }
        &:nth-of-type(2n-1){
            border-right: 1px solid #F5F5F5;
        }
        &:nth-of-type(1),&:nth-of-type(2){
            border-top: none;
        }
        }
    }
}
.mt-24{
    margin-top: 24px;
}
.van-popup-content{
  padding: 20px;
  p{
    padding: 5px 0;
  }
}
</style>