<template>
  <!-- 头部 -->
  <div class="header">
    <i class="header-back" @click="goBack"></i>
    <h3>{{title}}</h3>
  </div>
</template>
<script>
export default {
  props: ["title",'customBack'],
  methods: {
    goBack() {
      if (!this.customBack) {
        this.$router.back();
      } else {
        this.$emit('goBack');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// 头部
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  height: 100px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  color: #333333;
  .header-back {
    width: 20px;
    height: 35px;
    background: url("~@/assets/img/icon/fanhui.png") no-repeat;
    background-size: 100%;
    margin-right: 20px;
  }
  h3 {
    flex: 1;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #000000;
  }
}
</style>