<!-- 门店老板注册 -->
<template>
    <div class='login scroll'>
        <!-- <van-cell class="push_hint" value="绑定门店老板账号,实时查看门店业绩" /> -->
        <!-- 门店账号 -->
        <van-field v-model="form.loginName" 
                    :border="false"
                    placeholder="请输入门店的登录账号"
                    label="门店账号: "/>
        <!-- 账号密码 -->
        <van-field v-model="form.password" 
                  :border="false"
                  type="password" 
                  placeholder="请输入门店的登录密码"
                  label="登录密码: " />
        <div class="selectMake_btn">
            <van-button class="selectMake" type="default" @click="sumbit()">确定</van-button>
        </div>
    </div>
</template> 

<script>
import ajax from '@/api/index';
export default {
    data() {
        return {
            form : {
              //门店账号
              loginName : "",
              //账号密码
              password : ''
            }
           
        };
    },
    methods: {
        /**
         * 验证表单
         */
        validForm(){
            return new Promise((resolve)=>{
                if (!this.form.loginName) {
                    this.$toast('门店账号不能为空');
                    return;
                }
                if (!this.form.password) {
                    this.$toast('账号密码不能为空');
                    return;
                }
                resolve()
            })
        },
        /**
         * 确定
         */
        sumbit(){
            this.validForm().then(()=>{
                ajax.post('bossRegister',{
                    loginName : this.form.loginName,
                    password : this.form.password
                }).then(res=>{
                  console.log(res,1234)
                  if(res.responeCode==='0'){
                      this.$toast('店主注册成功');
                      this.$store.dispatch('getUserInfo').then((data) => {
                          this.$store.commit('removeKeepAlive','mine');
                          this.$router.push({
                              name : 'mine',
                          })
                      })
                  } else {
                      this.$toast(res.responeMsg || '店主注册失败');
                  }
                })
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.login{
  width: 100%;
  /deep/.van-cell{
    .van-field__label{
      span{
        color: #1d1d1d;
      }
    }
  }
  .push_hint{
    font-size: 24px;
    background-color: #f8f8f8;
  }
  .selectMake_btn{
    margin: 50px 24px;
  }
  .selectMake{
    width: 100%;
    height: 94px;
    border: 0;
    border-radius: 12px;
    background-color: #FF0202;
    color: #fff;
    font-size: 34px;
  }
}
</style>