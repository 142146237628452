<!-- 
  预约服务 - 选择项目
-->
<template>
    <div class='book-serve scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="选择项目"></header-tag> -->
        <div class="top-title">请您选择以下预约项目:</div>
        <div id="list">
            <div class="service-items" v-if="tbData.length>0">
                <div class="item" v-for="(item,index) in tbData" :key="index" @click="handleDetail(item)">
                    
                    <img :src="item.coverFullUrl" v-if="item.coverFullUrl">
                    <img src="@/assets/img/logo@2x.png" v-else>

                    <div class="service-info">
                        <h4 class="text-wrap-1">{{item.name}}</h4>
                        <p class="text-wrap-2">{{item.introduction}}</p>
                    </div>
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>

<script>
import ajax from '@/api/index';
import { mapState } from "vuex";
import headerTag from '@/components/headerTag';
export default {
    name : 'selectItem',
    components: {
        headerTag
    },
    data() {
        return {
            tbData : [],
            //门店iD
            storeId : '',
            //来自的路由
            from : "",
        };
    },
    created(){
        this.storeId = this.$route.query.storeId;
        this.from = this.$route.query.from;
        this.queryProjectList();
    },
    methods : {
        /**
         * 查询门店会员的项目列表
         */
        queryProjectList(){
            ajax.post('getStoreBookProjects',{
                storeId : this.storeId,
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.tbData = res.data || [];
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        /**
         * 跳转到员工列表
         * @param row 某行数据
         */
        handleDetail(row){

            //组件selectItem添加缓存
            this.$store.commit('addKeepAlive','selectItem');
            //组件selectStaff移除缓存
            this.$store.commit('removeKeepAlive','selectStaff');

            this.$router.push({
                name : 'selectStaff',
                query : {
                    projectId : row.id,
                    storeId : this.storeId,
                    from : this.from,
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.service-items{
    .item{
        display: flex;
        margin-bottom: 24px;
        padding: 30px 20px;
        background-color: #fff;
        img{
            width: 150px;
            height: 150px;
            border-radius: 12px;
            margin-right: 20px;
        }
        h4{
            font-size: 34px;
            color: #333;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p{
            font-size: 28px;
            color: #666;
        }
        .remark{
            overflow: hidden;
            text-overflow: ellipsis; 
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
        }
    }
}
</style>