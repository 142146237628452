<!-- 
  我的积分
--> 
<template>
    <div class='member_pck scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="我的积分"></header-tag> -->
        <div id="list">
            <div class="records" v-if="tbData.length>0">
                <div class="item" v-for="(item,index) in tbData" :key="index">
                    <p class="content">
                        <span>当前积分:</span>
                        <span>{{item.score}}</span>
                    </p>
                    <p class="content">
                        <span>服务门店:</span>
                        <span>{{item.storeName}}</span>
                    </p>
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>

<script>
import headerTag from '@/components/headerTag';
import ajax from '@/api/index';
export default {
    name : 'myPoints',
    components: {
        headerTag
    },
    data() {
        return {
        tbData : [],
        };
    },
    created(){
        this.queryScoreList();
    },
    methods : {
        /**
         * 查询门店会员的套餐列表
         */
        queryScoreList(){
            ajax.post('getCustomerScore').then(res=>{
                if(res.responeCode==='0'){
                    this.tbData = res.data || [];
                }else{
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.records{
    .item{
        background-color: #fff;
        border-radius: 12px;
        margin-bottom: 24px;
        padding:24px;
    }    
    .content{
        font-size: 28px;
        margin-bottom: 10px;
        span:nth-of-type(1){
            color: #999;
        }
        span:nth-of-type(2){
            margin-left: 20px;
            color: #333;
            font-weight: bold;
        }
    }
}
</style>