<!-- 
    我的会员卡
 -->
<template>
    <div class='member_card scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="我的会员卡"></header-tag> -->
        <div id="list">
            <!-- 列表-->
            <ul class="card-list" v-if="tbData.length>0">
                <li class="card-item" v-for="(item,index) in tbData" :key="index" :class="{'gold':index%2===0,'diamond':index%2===1}">
                    <p>
                        <span>卡号：{{index%2}}</span>
                        <span>{{item.cardNumber}}</span>
                    </p>
                    <p>
                        <span>储值金额：</span>
                        <span>{{item.cardAmount | moneyFilter}}</span>
                    </p>
                    <p>
                        <span>赠送金额：</span>
                        <span>{{item.givenAmount | moneyFilter}}</span>
                    </p>
                    <p>
                        <span>所属门店：</span>
                        <span>{{item.storeName}}</span>
                    </p>
                    <div class="indate">有效期：{{item.endDate}}</div>
                </li>
            </ul>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>

<script>
import ajax from '@/api/index';
import headerTag from '@/components/headerTag';
export default {
    name : 'myClubCard',
    components: {
        headerTag
    },
    data() {
        return {
            tbData : [],
        };
    },
    created(){
        this.queryCardList();
    },
    methods : {
        /**
         * 查询门店会员的卡列表
         */
        queryCardList(){
            ajax.post('getCustomerCards').then(res=>{
                if(res.responeCode==='0' && res.data){
                this.tbData = res.data.customerCards || [];
                }else{
                this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.member_card{
    width: 100%;
    .card-list{
        .card-item{
            width: 100%;
            height: 360px;
            position: relative;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url('~@/assets/img/wdhyk_image_jk@2x.jpg');
            margin-bottom: 24px;
            p{              
                padding-left: 30px;
                font-size: 32px;
                color: #fff;
                &:first-child{
                    padding-top: 60px;
                }
            }
            .indate {
                position: absolute;
                left: 30px;
                bottom: 20px;
                color: #fff;
            }
            &.gold{
                background-image: url('~@/assets/img/wdhyk_image_jk@2x.jpg');
            }
            &.diamond{
                background-image: url('~@/assets/img/wdhyk_image_zsk@2x.jpg');
            }
        }
    }
}
</style>