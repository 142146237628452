<!-- 日期选择器 -->
<template>
  <div  class='date-picker'>
    <van-popup 
        v-model="visible" 
        position="bottom">
        <van-datetime-picker
            type="date"
            title="选择年月日"
            v-model="value"
            :min-date="dateRange[0]"
            :max-date="dateRange[1]"
            @confirm="onConfirm"
            @cancel="hide()" />
    </van-popup>
  </div>
</template>
<script>
export default {
  name:'datetime-picker' ,
  data(){
      return{
        visible : false,
        //确认执行的回调函数
        confirmCallback : null,
        //取消执行的回调函数
        cancelCallback : null,
        // 可选的日期范围
        dateRange : [],
        value : new Date(),
      }
  },
  methods : {
      /**
       * 显示模态框
       * @param range 可选范围
       * @param {Function} confirmCallback 确认回调
       * @param {Function} cancelCallback 取消回调
       */
      show ({ range, confirmCallback = null, cancelCallback = null }){
            this.visible = true;
            if(range) {
                this.dateRange = range;
            } else {
                this.dateRange = [new Date(this.$moment().add({years: -1})),new Date()];//默认一年前到今天
            }
            if (confirmCallback && typeof confirmCallback == 'function') {
                this.confirmCallback = confirmCallback;
            }
            if (cancelCallback && typeof cancelCallback == 'function') {
                this.cancelCallback = cancelCallback;
            }
      },
      /**
       * 关闭模态框
       */
      hide(){
          this.visible = false;
      },
      /**
       * 确认
       */
      onConfirm(date){
        if (this.confirmCallback) {
            this.confirmCallback( this.$moment(date).format('YYYY-MM-DD') );
            this.hide();
        }
      }
  }
}
</script>
<style scoped lang='scss'>

</style>