<!-- 
  预约服务 - 选择服务时间
-->
<!-- 
  提交预约
-->
<template>
    <div class='member_pck scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="选择服务时间"></header-tag> -->
        <div class="top-title">请您选择预约时间:</div>
        <div id="list">
            <div class="cell-group">
                <!-- 选择服务时间 -->
                <div class="cell-tb">选择日期</div>
                <ul class="calendar_ul">
                <li class="calendar_td"
                    @click="handleSelectDate(item)"
                    v-for="(item,index) of weeks" 
                    :key="index">
                    <div :class="['date',{'active-date' : item == selectDate}]">
                        <p class="calendar_day">
                            <span v-if="item.fullDay===nowDate">今天</span>
                            <span v-else>{{item.shortDay | filterShortDayCN}}</span>
                        </p>
                        <p class="calendar_week">{{item.week | filterWeekCN}}</p>
                    </div>
                </li>
                </ul>
                <!-- 选择服务场次 -->
                <div class="session_tb">
                    <div v-for="(item,index) in times" :key="index">
                        <div class="cell-tb" v-if="item.data.length>0">{{item.title}}</div>
                        <ul class="cell-tr">
                            <li :class="['cell-td',{'disabled-cell-td': !time.state},{'active-cell-td': time == selectSession}]"
                                @click="handleSelectTime(time)"
                                v-for="(time,index) of item.data" 
                                :key="index">
                                {{time.paramValue}}
                            </li>
                        </ul>
                    </div>
                    <van-empty description="无记录" v-if="times.length===0"/>
                </div>
            </div>
        </div>     
        
        <div class="footer">
            <div class="footer_left">
                <span class="make_time_span">预约时间：</span>
                <span class="make_time_span" style="color: #FF0202;">{{selectDate.shortDay}}&nbsp;{{selectSession.paramValue}}</span>
            </div>
            <van-button class="selectMake" type="default" @click="submit()">确认预约</van-button>
        </div>
    </div>
</template>

<script>
import ajax from '@/api/index';
import headerTag from '@/components/headerTag';
export default {
    name: 'selectTime',
    components: {
        headerTag
    },
    data() {
        return {
            //可选日期
            weeks: [],
            //可选时间
            times: [],
            //当前选中的日期
            selectDate: {},
            // 选中的场次时间
            selectSession: {},
            // 员工信息
            employeeInfo : {},
            //门店id
            storeId : '',
            //员工id
            staffId : '',
            //项目id
            projectId : '',
            //当天
            nowDate : "",
            from : "",
        };
    },
    created(){
       this.storeId = this.$route.query.storeId;
       this.staffId = this.$route.query.staffId;
       this.projectId = this.$route.query.projectId;
       this.from = this.$route.query.from || 'mine';
       this.nowDate = this.$moment(new Date()).format('YYYY-MM-DD');
       this.getTimes();
    },
    methods: {       
        // 获取门店可预约时间列表
        getTimes(){
            ajax.post('getStoreBookTimes',{
                storeId : this.storeId,
            }).then(res=>{
                if(res.responeCode==='0' && res.data){
                  this.weeks = res.data.weeks || [];
                  let times = res.data.times || [];
                  let am = [], pm = [], night = [];
                  times.map(val => {
                      if (val.state ==='true') {
                          if (val.paramValue <= "12:30" && val.paramValue >= "09:00") {
                            am.push(val);
                          } else if (val.paramValue <= "18:30" && val.paramValue > "12:30") {
                            pm.push(val);
                          } else if (val.paramValue <= "23:30" && val.paramValue > "18:30") {
                            night.push(val);
                          }
                      }
                  });
                  //按上午、下午、晚上过滤出可预约的时间
                  this.times = [
                      {
                        title: "上午",
                        data: am
                      },
                      {
                        title: "下午",
                        data: pm
                      },
                      {
                        title: "晚上",
                        data: night
                      }
                  ];
                  //默认选中第一个日期
                  if (this.weeks.length){
                    this.selectDate = this.weeks[0];
                  }
                } else { 
                    this.times = [];
                }
            })
        },
        // 返回
        goBack() {
            this.$router.back();
        },
        // 点击日期
        handleSelectDate(date) {
            this.selectDate = date;
        },
        // 点击场次时间
        handleSelectTime(time) {
            this.selectSession = time;
        },
        // 提交预约
        submit() {
            if (!this.selectDate.fullDay) {
                this.$toast('请选择日期');
                return;
            }
            if (!this.selectSession.paramValue) {
                this.$toast('请选择预约场次');
                return;
            }
            ajax.post('doBooking',{
                storeId : this.storeId,//门店id
                staffId : this.staffId,//员工id
                projectId : this.projectId,//项目id
                date : `${this.selectDate.fullDay} ${this.selectSession.paramValue}`,//预约时间 yyyy-MM-dd HH:mm 需要拼接日期和时间段
            }).then(res=>{
                if (res.responeCode==='0') {
                  this.$toast('预约成功');
                  setTimeout(()=>{
                      this.$router.push({
                        name : this.from,
                      })
                  },1500);
                } else {
                  this.$toast( res.responeMsg || '预约失败');
                }
            })
        }
    },
    filters : {
        //日期’-‘替换成月
        filterShortDayCN(content){
            if(content){
              return content.replace('-','月') + '日'
            } else { 
              return '-'
            }
        },
        //获取星期中文名
        filterWeekCN(content){
            if(content){
              if (content==='Mon'){
                return '星期一';
              } else if(content==='Tue') {
                return '星期二';
              } else if(content==='Wed') {
                return '星期三';
              } else if(content==='Thu') {
                return '星期四';
              } else if(content==='Fri') {
                return '星期五';
              } else if(content==='Sat') {
                return '星期六';
              } else if(content==='Sun') {
                return '星期天';
              } else {
                return content;
              }
            } else { 
              return '-'
            }
        }
    }
}
</script>
<style lang='scss' scoped>
.member_pck{
    position: relative;
    .footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        z-index: 100;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer_left{
            margin-left: 3%;
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .make_time_span{  
                color: #999;
                font-size: 28px;
                margin-right: 10px;
                font-weight: bold;
            }
        }
        .selectMake{
            width: 240px;
            height: 100%;
            border: 0;
            background-color: #FF0202;
            color: #fff;
            border-radius: 0;
            font-size: 34px;
            font-weight: bold;
        }
    }
}
.cell-tb{
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 28px;
    color: #333;
}
.calendar_ul{
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    text-align:center;
    .calendar_td{
        display: inline-block;
        margin-right: 16px;
        .date{
            width: 140px;
            height: 98px;
            background-color: #fff;
            border-radius: 12px;
            color: #333;
            padding-top: 15px;
            box-sizing: border-box;
            &.active-date {
                background-color: #FF0202;
                border-radius: 12px;
                color: #fff;
            }
        } 
    }
}
.session_tb{
    padding-bottom: 150px;
    .cell-tb{
      margin-top: 10px;
    }
    .cell-tr{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .cell-td{
            width: 24%;
            height: 66px;
            line-height: 66px;
            margin-right: 1%;
            margin-top:16px;
            color: #333;
            background-color: #fff;
            border-radius: 12px;
            text-align: center;
            &.active-cell-td{
              background-color: #FF0202;
              color: #fff;
            }
        }
        .cell-td:nth-child(4n) {
          margin-right: 0;
        }
        .disabled-cell-td{
          background-color: #cecece;
        }
    }
}
</style>