<!-- 
  预约记录
--> 
<template>
    <div class='member_pck scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="预约记录"></header-tag> -->
        <div id="list">
            <div class="records" v-if="tbData.length>0">
                <div class="item" v-for="(item,index) in tbData" :key="index">
                    <p class="d-flex j-sb" style="margin-bottom:14px;">
                        <span :class="item.bookState ==='已预约' ? 'name text-danger' : 'name'">{{item.bookState}}</span>
                        <span class="time">
                            <img src="@/assets/img/fwjl_icon_shij@2x.png" alt="" class="time-icon">
                            <span>{{item.bookDate}}
                            <span v-if="item.bookState ==='已预约'" class="font-weight-bold" @click="cancelBook(item)">[取消]</span>
                            </span>
                        </span>
                    </p>
                    <p class="content">
                        <span>服务员工:</span>
                        <span>{{item.staffName}}</span>
                    </p>
                    <p class="content">
                        <span>服务项目:</span>
                        <span>{{item.bookTypeName}}[{{item.projectName}}]</span>
                    </p>
                    <p class="content">
                        <span>服务门店:</span>
                        <span>{{item.storeName}}</span>
                    </p>
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>

<script>
import headerTag from '@/components/headerTag';
import ajax from '@/api/index';
export default {
    name : 'servieRecord',
    components: {
        headerTag
    },
    data() {
      return {
          tbData : [],
      };
    },
    created(){
        this.queryBookingList();
    },
    methods : {
        /**
         * 获取会员用户的预约记录
         */
        queryBookingList(){
            ajax.post('getCustomerBookings',{
            }).then(res=>{
                if(res.responeCode==='0'){
                  this.tbData = res.data || [];
                }else{
                  this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        cancelBook(row){
            this.$dialog.alert({
              title: "提示", //加上标题
              message: "您确认取消该预约? "+row.staffName+"["+row.projectName+"]", //改变弹出框的内容
              showCancelButton: true,
              confirmButtonText: "确认", //改变确认按钮上显示的文字
              cancelButtonText: "取消" //改变取消按钮上显示的文字
            })
            .then(() => { //点击确认按钮后的调用
                    console.log("点击了确认")
                    ajax.post('doCancelBooking',
                        {bookId:row.id,storeId:row.storeId
                    }).then(res=>{
                      if(res.responeCode==='0'){
                        this.$toast("取消预约成功");
                        this.queryBookingList();
                      }else{
                        this.$toast(res.responeMsg || '操作失败');
                      }
                    })
            })
            .catch(() => { //点击取消按钮后的调用
                //console.log("点击了取消按钮噢"+JSON.stringify(err))
            })


        }
    },
}
</script>

<style lang='scss' scoped>
.records{
    .item{
        background-color: #fff;
        border-radius: 12px;
        margin-bottom: 24px;
        padding:24px;
    }
    .name {
        color: #333;
        font-weight: bold;
        font-size: 34px;
    }
    .time{
        color: #666;
      font-size: 32px;
        .time-icon{
            vertical-align: middle;
            width :32px;
            height: 32px;
            margin-right: 10px;
        }
    }
    .price{
        color: #FF0202;
        font-size: 34px;
        font-weight: bold;
        text-align: right;
        padding-top: 15px;
    }
    .content{
        font-size: 28px;
        margin-bottom: 10px;
        span:nth-of-type(1){
            color: #999;
        }
        span:nth-of-type(2){
            margin-left: 20px;
            color: #333;
            font-weight: bold;
        }
    }
}
</style>