<!-- 
  预约服务 - 选择员工
-->
<template>
    <div class='book-serve scroll'>
        <!-- 头部 -->
        <!-- <header-tag title="预约服务"></header-tag> -->
        <div class="top-title">请您选择以下服务工作人员:</div>
        <div id="list">
            <ul class="serve-list" v-if="tbData.length>0">
                <li v-for="(item,index) in tbData" :key="index" @click="handleDetail(item)">
                    <div class="left">
                        <img :src="item.headImgUrl" alt="" v-if="item.headImgUrl">
                        <img src="@/assets/img/default-tx.jpg" alt="" v-else>
                    </div>
                    <div class="right">
                        <div class="d-flex a-center">
                            <span class="name">{{item.nickname}}</span>
                            <span class="level" v-if="item.levelName">
                                <img src="@/assets/img/yyfw_icon_gjmrs@2x.png" class="level-icon">
                                <span class="level-name">{{item.levelName}}</span>
                            </span>
                        </div>
                        <p class="level-intro text-wrap-1">{{item.resume || ''}}</p>
                    </div>
                </li>
            </ul>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>

<script>
import ajax from '@/api/index';
import headerTag from '@/components/headerTag';
export default {
    name : 'selectStaff',
    components: {
        headerTag
    },
    data() {
        return {
            tbData : [],
            // 门店id
            storeId : '',
            //项目id
            projectId : '',
            //来自的路由
            from : "",
        };
    },
    created(){
        this.storeId = this.$route.query.storeId || '' ;
        this.projectId = this.$route.query.projectId ;
        this.from = this.$route.query.from;
        this.queryStaffList();
    },
    methods : {
        /**
         * 查询员工列表
         */
        queryStaffList(){
            ajax.post('getCurrentStoreStaffs',{
                storeId : this.storeId,
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.tbData = res.data || [];
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        /**
         * 跳转到选择时间
         * @param row 某行数据
         */
        handleDetail(row){
             //组件selectStaff添加缓存
            this.$store.commit('addKeepAlive','selectStaff');
            this.$router.push({
                name : 'selectTime',
                query : {
                    //员工id
                    staffId : row.staffId,
                    //项目id
                    projectId : this.projectId,
                    //门店id
                    storeId : this.storeId,
                    //来自的路由
                    from : this.from,
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.serve-list{
    li{
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 12px;
        padding: 30px 24px;
        margin-bottom: 24px;
    }
    .left{
        flex-shrink: 0;
        margin-right: 10px;
        img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }        
    }
    .right{
        flex: 1;
        color: #999;
        margin-left: 20px;
        .name{
            font-weight: bold;
            color: #333;
            font-size: 34px;
            margin-right: 10px;
        }    
        .level{
            background-color: #7C87FD;
            border-radius: 24px;
            height: 40px;
            line-height: 36px;
            padding: 0 20px;
            display: inline-block;
            .level-icon{
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-right: 5px;
            }
            .level-name{
                font-size: 20px;
                color: #fff;
            }
        }
        .level-intro{
            color: #666;
            margin-top:16px
        }
    }
}
</style>