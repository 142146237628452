<!-- 会员注册 -->
<template>
    <div class='login scroll'>
        <!-- 会员卡头部 -->
        <!-- <header-tag title="绑定门店"></header-tag> -->
        <!-- <van-cell class="push_hint" value="绑定账号,接收消费提醒推送" /> -->
        <van-field v-model="storeNo" 
                    type="text" 
                    placeholder="请输入门店编号"
                    label="门店编号"></van-field>
        <van-field v-model="mobile" 
                    type="tel" 
                    placeholder="请输入手机号"
                    label="手机号"></van-field>
        <van-field 
            v-model="code"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码">
            <template #button>
              <van-button size="small" type="default" :disabled="isDisabled" class="send-code" @click="sendCode()">{{verifyTimer}}</van-button>
            </template>
        </van-field>
        <div class="selectMake_btn">
            <van-button type="default" class="selectMake" @click="sumbit()">确定</van-button>
        </div>
    </div>
</template>

<script>
import ajax from '@/api/index';
import { verification } from '@/assets/js/util';
import headerTag from '@/components/headerTag';
export default {
    data() {
        return {
            //门店编号
            storeNo : "",
            //手机号
            mobile : "", 
            //验证码
            code: "",
            isDisabled: false,
            verifyTimer: "获取验证码",
        };
    },
    components: {
        headerTag
    },
    methods: {
        /**
         * 验证表单
         */
        validForm(){
            return new Promise((resolve)=>{
                if (!this.storeNo) {
                    this.$toast('门店编号不能为空');
                    return;
                }
                if (!this.mobile) {
                    this.$toast('手机号不能为空');
                    return;
                }
                if (!verification.tel.test(this.mobile)) {
                    this.$toast({
                      message: '手机号格式不对',
                      position: "middle",
                      duration: 1500,
                      className: "icon"
                    });
                    return;
                }
                resolve()
            })
        },
        /**
         * 发送验证码
         */
        sendCode(){
            this.validForm().then(()=>{
              ajax.post('getRegisterSmsCode',{
                  storeNo : this.storeNo,
                  mobile :this.mobile,
              }).then( res=>{
                  if (res.responeCode==='0') {
                      this.$toast('发送成功,请注意查收短信');
                      this.countDown();
                  } else {
                      this.$toast(res.responeMsg || '发送失败');
                  }
              })
            })
        },
        // 倒计时
        countDown(){
            this.isDisabled = true;
            let verifyTimer = 60;
            this.verifyTimer = `${verifyTimer}s后重发`;
            const timer = setInterval(() => {
                verifyTimer--;
                this.verifyTimer = `${verifyTimer}s后重发`;
                if (verifyTimer < 1) {
                  this.isDisabled = false;
                  this.verifyTimer = "获取验证码";
                  clearInterval(timer);
                }
            }, 1000);
        },
        /**
         * 确定
         */
        sumbit(){
          this.validForm().then(()=>{
              if (!this.code) {
                  this.$toast('验证码不能为空');
                  return;
              }
              ajax.post('customerRegister',{
                  storeNo : this.storeNo,
                  mobile : this.mobile,
                  code : this.code
              }).then(res=>{
                if(res.responeCode==='0'){
                    this.$toast('会员注册成功');
                    this.$store.dispatch('getUserInfo').then((data) => {
                        this.$store.commit('removeKeepAlive','mine');
                        this.$router.push({
                            name : 'mine'
                        })
                    })
                }else{
                    this.$toast(res.responeMsg || '会员注册失败');
                }
              })
          })
        },
    },
}
</script>
<style lang='scss' scoped>
.login{
  width: 100%;
  /deep/.van-cell{
    .van-field__label{
      span{
        color: #1d1d1d;
      }
    }
  }
  .push_hint{
    font-size: 24px;
    background-color: #f8f8f8;
  }
  .selectMake_btn{
    margin: 50px 24px;
  }
  .selectMake{
    width: 100%;
    height: 94px;
    border: 0;
    border-radius: 12px;
    background-color: #FF0202;
    color: #fff;
    font-size: 34px;
  }
  .send-code{
      width: 160px;
      height: 46px;
      border: 0;
      border-radius: 12px;
      background-color: #FF0202;
      color: #fff;
  }
}
</style>