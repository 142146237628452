<!--
    内容：授权页面
    作者：ada-xu
-->
<template>
    <div></div>
</template>
<script>
import ajax from '@/api/index';
export default {
    name: "auth",
    data() {
        return {}
    },
    created() {
        this.wxlogin();
    },
    methods: {
        //获取url中"?"符后的字串
        getParmas() {
            let url = location.search; 
            let theRequest = new Object();
            if (url.indexOf("?") != -1) {
                let str = url.substr(1);
                let strs = str.split("&");
                for (let i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }
            return theRequest;
        },
        //授权登录
        wxlogin() {
            let params = this.getParmas();
            console.log(JSON.stringify(params));
            ajax.post('auth2Login',{
                code : params.code || null,
                state : params.state || null,
            }).then(res=>{
                if(res.responeCode==='0' && res.data && res.data.openid){
                    this.$store.dispatch('getUserInfo').then(() => {
                        this.$router.push({
                            name : this.state || 'mine',
                        })
                    })
                } else {
                    //授权失败，回到主页重新唤起授权
                    this.$router.push({
                        name : this.state || 'mine',
                    })
                }
            });
        },
    }
};
</script>