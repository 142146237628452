<!--选择门店-->
<template>
  <van-popup v-model="visible" :style="{ width:'95%'}" :close-on-click-overlay="closeable" :closeable="closeable">
        <div class="van-popup-title text-dark">选择门店</div>
        <ul class="stores-box">
            <li v-for="(item,index) in storeList" :key="index">
                <img class="shop_img" :src="require('@/assets/img/icon/dingdan.png')" alt="">
                <div class="shop_info">
                    <span class="shop_name">{{item.fullName}}</span>
                    <span class="shop_site">{{item.address}},联系方式{{item.mobile}}</span>
                </div>
                <van-button plain type="primary" v-if="item.storeNo === selectId" style="border:none">已选</van-button>
                <van-button class="selected_shop" type="info" @click="selectStore(item)" v-else>选择</van-button>
            </li>
        </ul>
    </van-popup>
</template>
<script>
import ajax from '@/api/index';
export default {
    name:'' ,
    data(){
        return{
            //是否显示模态框
            visible : false,
            storeList : [],
            closeable : false,
            selectId : "",
            confirmCallback : null,
        }
    },
    methods : {
        /**
         * 显示模态框
         * @param storeList 所有门店
         * @param closeable 是否显示关闭按钮
         * @param selectId 选中的门店
         * @param confirmCallback //确认执行的回调函数
         */
        show ({storeList,closeable=false,selectId,confirmCallback}){
            if (confirmCallback && typeof confirmCallback === 'function') {
                this.confirmCallback = confirmCallback;
            }            
            this.storeList = storeList;
            this.closeable = closeable;
            this.selectId = selectId;
            this.visible = true;
        },
        /**
         * 隐藏模态框
         */
        hide(){
            this.visible = false;
        },
        /**
         * 选择门店
         */
        selectStore(item){
            if(this.confirmCallback){
                this.confirmCallback(item.storeId)
            }
            this.hide();
            // // 切换门店后需要通知后台
            // ajax.post('changeUserStoreId',{
            //     storeId : item.id
            // }).then(res=>{
            //     if (res.responeCode==='0') {
            //         this.$store.dispatch('getUserInfo');
            //         this.$emit('on-select',item);
            //         this.hide();
            //     } else {
            //         this.$toast(res.responeMsg || '选择失败');
            //     }
            // })            
        }
    }
}
</script>
<style scoped lang='scss'>
    .van-popup{   
        border-radius: 30px;
        overflow: hidden;
    }
    .van-popup-title{
        text-align: center;
        padding-top: 30px;
        font-size: 32px;
        font-weight: bold;
    }
    .stores-box{
        padding: 10px 24px;
        box-sizing: border-box;
        min-height: 5vh;
        max-height: 70vh;
        overflow-y: auto;
        li{
            margin: 24px 0;
            padding: 30px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            box-shadow:0 0 14px 3px rgb(233, 232, 232);
            .shop_img{
                width: 70px;
                height: 70px;
                object-fit: scale-down;
            }
            .shop_info{
                flex: 1;
                margin: 0 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                line-height: 35px;
                .shop_name{
                    font-weight: bold;
                }
                .shop_site{
                    color: #a4a4a4;
                    font-size: 20px;
                }
            }
            .selected_shop{
                color: #039789;
                height: 50px;
                background-color: #fff;
                border: 1px solid #039789;
                border-radius: 50px;
                font-size: 24px;
            }
        }
    }
</style>