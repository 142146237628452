<!-- 
  我的套餐
-->
<template>
    <div class='member_pck scroll'>
        <!-- 套餐头部 -->
        <!-- <header-tag title="我的套餐"></header-tag> -->
        <div id="list">
            <ul class="pck-list" v-if="tbData.length>0">
                <li v-for="(item,index) in tbData" :key="index">
                    <div class="left">                    
                        <p><label>套餐名称：</label><span>{{item.projectName}}</span></p>
                        <p><label>有效期：</label><span>{{item.endDate || '长期有效'}}</span></p>
                        <p><label>服务门店：</label><span>{{item.storeName}}</span></p>
                    </div>
                    <div class="right">剩余<span>{{item.validNumber}}</span>次</div>
                </li>
            </ul>
        </div>        
        <van-empty description="无记录" v-if="tbData.length===0"/>
    </div>
</template>

<script>
import ajax from '@/api/index';
import headerTag from '@/components/headerTag';
export default {
    name : 'myPackage',
    components: {
        headerTag
    },
    data() {
        return {
        tbData : [],
        };
    },
    created(){
        this.queryPckList();
    },
    methods : {
        /**
         * 查询门店会员的套餐列表
         */
        queryPckList(){
            ajax.post('getCustomerProjectItems').then(res=>{
                if(res.responeCode==='0'){
                this.tbData = res.data || [];
                }else{
                this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
    },
}
</script>
<style lang='scss' scoped>
.pck-list{
    li{
        display: flex;
        background-color: #fff;
        border-radius: 12px;
        padding: 30px 24px;
        margin-bottom: 24px;
    }
    .left{
        flex: 1;
        p {
            color: #999;
            font-size: 28px;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            span{
                color: #333;
                font-weight: bold;
            }
            label{
                width: 140px;
                display: inline-block;
            }
        }
    }
    .right{
        flex-shrink: 0;
        color: #999;
        margin-left: 20px;
        span{
            color: #FF0202;
            padding: 0 3px;
        }
    }
}
</style>