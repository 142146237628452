<template>
    <div class="filter-section">
        <div class="select-store">
            <span class="text-wrap-1">{{currentStore.storeName || '未绑定门店'}}</span>
            <img src="@/assets/img/wdzl_icon_qieh@2x.png" @click="handleSelect()" alt="">
        </div>
        <div class="search-box">
            <div class="search-date">
                <div class="date" @click="openPicker('startDate')">
                    <img src="@/assets/img/wdzl_icon_riqi@2x.png"/>
                    <span>{{startDate}}</span>
                </div>
                <div class="mx-10">至</div>
                <div class="date" @click="openPicker('endDate')">
                    <img src="@/assets/img/wdzl_icon_riqi@2x.png"/>
                    <span>{{endDate}}</span>
                </div>
            </div>
            <div class="search-btn">
                <van-button @click="handleSearch()">搜索</van-button>
            </div>
        </div>
        <!-- 选择日期模态框 -->
        <datetimePicker ref="datetimePicker"></datetimePicker>
        <!-- 选择门店模态框 -->
        <selectStoreModal ref="selectStoreModal" @on-select="selectCallback"></selectStoreModal>
    </div>
</template>
<script>
import ajax from '@/api/index';
import datetimePicker from '@/components/datetimePicker/index';
import selectStoreModal from '@/components/selectStoreModal/index';
export default {
    name:'' ,
    props : {
        // 用户类型
        'opt-flag' : {
            type : String,
            default : "member"
        }
    },
    data(){
        return {
            //开始时间
            startDate: "",
            //结束时间
            endDate: "",
            //所有门店
            allStore: [],
            //当前选中的门店
            currentStore : {},
        }
    },   
    components:{
        datetimePicker,
        selectStoreModal,
    },
    created(){
        this.init();
        this.queryStores();
    },
    methods : {
        /**
         * 初始化
         */
        init(){
            //默认本月1号到当天
            let nowDay = new Date();
            let firstDay = this.$moment(nowDay).set('date', 1)
            this.startDate = this.$moment(firstDay).format('YYYY-MM-DD'); 
            this.endDate = this.$moment(nowDay).format('YYYY-MM-DD');
        },
        /**
         * 打开日期选择器
         */
        openPicker(obj){
            this.$refs.datetimePicker.show({
                confirmCallback : (data)=>{
                    this[obj] = data
                }
            })
        },
        /**
         * 查询门店列表
         */
        queryStores() {
            ajax.post('getCustomerStores',{
                optFlag : this.optFlag,
            }).then(res => {
                if (res.responeCode === '0') {
                    this.allStore = res.data || [];
                    if (this.allStore.length===0) {
                        //未绑定门店
                        this.$toast('当前用户未绑定门店,请进行注册');
                    } else {
                        this.currentStore = this.allStore[0];
                        this.handleSearch();
                    }
                } else {
                    this.$toast(res.responeMsg || '门店列表获取失败');
                }
            })
        },
        /**
         * 搜索
         */
        handleSearch(){
            this.$emit('search',{
                startDate : this.startDate,
                endDate : this.endDate,
                storeId : this.currentStore.storeId,
            })
        },
        /**
         * 打开选择门店弹窗
         */
        handleSelect() {
                this.$refs.selectStoreModal.show({
                    storeList : this.allStore,
                    closeable : true,
                    selectId : null,
                });
        },
        /**
         * 选择门店回调
         */
        selectCallback(item){
            this.currentStore = item;
            this.handleSearch();
        }
    },
}
</script>
<style scoped lang='scss'>
    .filter-section{
        .select-store{
            display: flex;
            align-items: center;
            span{
                color: #333;
                font-size: 28px;
                font-weight: bold;
            }
            img{
                width: 26px;
                height: 24px;
                margin-left: 20px;
            }
        }
        .search-box{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
        .search-date{
            display: flex;
            align-items: center;
            .date{
                border-radius: 12px;
                background-color: #fff;
                width: 240px;
                height: 66px;
                line-height: 66px;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding : 0 24px;
                img{
                    width: 28px;
                    height: 28px;
                    margin-right: 16px;
                }
                span{
                     color: #333;
                }
            }
            .mx-10{
                margin: 0 15px;
            }
        }
        /deep/ .van-button{
            height: 66px;
            width: 134px;
            border-radius: 12px;
            font-size: 28px;
            background-color: #FF0202;
            color: #fff;
        }
    }
    
</style>